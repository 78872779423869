import { validateText } from './renderHelpers';

const getPageMeta = (globals, page, location) => {
  let title = page.title;
  let description = validateText(page.seo_description)
    ? page.seo_description
    : globals.seo_description;
  let keywords = validateText(page.seo_keywords)
    ? page.seo_keywords
    : globals.seo_keywords;
  let img_alt = validateText(page.seo_image_alt)
    ? page.seo_image_alt
    : globals.seo_image_alt;
  let fb_image = page.seo_image
    ? page.seo_image.facebook
    : globals.seo_image.facebook;

  let twitter_image = page.seo_image
    ? page.seo_image.twitter
    : globals.seo_image.twitter;

  let pageTitle = `${title.text} | Citizen Relations`;
  let meta = [
    { property: `og:site_name`, content: `Citizen Relations` },
    { name: `title`, content: pageTitle },
    { property: `og:title`, content: pageTitle },
    { name: `description`, content: description.text },
    { name: `keywords`, content: keywords.text },
    { property: `og:description`, content: description.text },
    {
      property: `og:image`,
      content:
        fb_image.localFile && fb_image.localFile.childImageSharp
          ? fb_image.localFile.childImageSharp.fixed.src
          : fb_image.url
    },
    {
      property: `og:image:width`,
      content: fb_image.dimensions ? fb_image.dimensions.width : null
    },
    {
      property: `og:image:height`,
      content: fb_image.dimensions ? fb_image.dimensions.height : null
    },
    { property: `og:image:alt`, content: img_alt.text },
    {
      property: `og:url`,
      content: `https://www.citizenrelations.com${location.pathname}`
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`
    },
    {
      name: `twitter:image`,
      content:
        twitter_image.localFile && twitter_image.localFile.childImageSharp
          ? twitter_image.localFile.childImageSharp.fixed.src
          : twitter_image.url
    }
  ];
  return meta.filter(m => m.content);
};

export default getPageMeta;
