import { PrismicLink, validateHtml, validateText } from "utils/renderHelpers";
import React, { PureComponent } from "react";
import { animatedChild, animatedContainer } from "utils/commonPoses";

import { CgArrowLongRight } from "react-icons/cg";
import Container from "react-bulma-components/lib/components/container";
import { Section } from "components/animated";
import Waypoint from "react-waypoint";
import classNames from "classnames/bind";
import posed from "react-pose";
import styles from "./JoinCallout.module.scss";

const cx = classNames.bind(styles);

const AnimatedSection = posed(Section)(animatedContainer);
const AnimatedLink = posed.div(animatedChild);
const AnimatedParagraph = posed.article(animatedChild);
const AnimatedHeading = posed.h2(animatedChild);

export default class JoinCallout extends PureComponent {
  state = { visible: false };

  onEnter = () => {
    this.setState({ visible: true });
  };

  render() {
    let { primary } = this.props;
    let { heading, paragraph, link_label, link } = primary;

    return (
      <Waypoint
        key={"joinCallout"}
        scrollableAncestor={typeof window === "undefined" ? null : window}
        onEnter={this.onEnter}
        topOffset={`30%`}
        bottomOffset={`30%`}
      >
        <AnimatedSection
          initialPose="hidden"
          pose={this.state.visible ? "visible" : "hidden"}
          className={cx({ section: true })}
        >
          <Container>
            {validateText && <AnimatedHeading>{heading.text}</AnimatedHeading>}
            {validateHtml(paragraph) && (
              <AnimatedParagraph
                className={styles.article}
                dangerouslySetInnerHTML={{ __html: paragraph.html }}
              />
            )}
            {link && (
              <AnimatedLink>
                <PrismicLink
                  className={styles.button}
                  link={link}
                  label={link_label}
                >
                  <span>{link_label}</span>
                  <i
                    className="icon"
                    style={{ fontSize: 28, paddingLeft: `1rem` }}
                  >
                    <CgArrowLongRight />
                  </i>
                </PrismicLink>
              </AnimatedLink>
            )}
          </Container>
        </AnimatedSection>
      </Waypoint>
    );
  }
}
