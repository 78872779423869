import React, { Component } from "react";
// Components
import Helmet from "react-helmet";

import JoinCallout from "slices/JoinCallout";
import getPageMeta from "utils/getPageMeta";

// graphql
import { graphql } from "gatsby";

class PostPage extends Component {
  state = {
    page: this.props.data.page,
  };

  renderSlice = (slice) => {
    if (!slice) {
      return null;
    }

    let Cmp;
    switch (slice.slice_type) {
      case "callout":
        Cmp = JoinCallout;
        break;
      default:
        Cmp = null;
        break;
    }
    if (!Cmp) {
      return null;
    }
    return <Cmp {...slice} key={slice.id} />;
  };

  render() {
    // Set the document object
    const { page } = this.state;
    const { title, body } = page.data;
    return (
      <React.Fragment>
        <Helmet
          title={`${title.text} | Citizen Relations`}
          meta={getPageMeta(
            this.props.pageContext.globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        {body.map((slice) => {
          return this.renderSlice(slice);
        })}
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query($uid: String!) {
    page: prismicPost(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        body {
          ... on PrismicPostBodyCallout {
            id
            slice_type
            primary {
              heading {
                text
              }
              paragraph {
                html
              }
            }
          }
        }
      }
    }
  }
`;

export default PostPage;
